/* eslint react/jsx-key: off */
import { RichTextInput } from 'ra-input-rich-text';
import { AutocompleteInput, Create, ReferenceInput, required, SimpleForm, TextInput } from 'react-admin';
const MailCreate = () => {
  const filterToQuery = (searchText: string) => ({ last_name: `%${searchText}%` });

  return (
    <>
      <Create redirect="show">
        <SimpleForm>
          <ReferenceInput
            source="guest_id"
            reference="guest"
            autoFocus
            validate={[required()]}
            sort={{ field: 'last_name', order: 'ASC' }}
          >
            <AutocompleteInput filterToQuery={filterToQuery} fullWidth />
          </ReferenceInput>

          <TextInput source="from" fullWidth />
          <TextInput source="to" fullWidth />
          <TextInput source="title" fullWidth />
          <RichTextInput source="body" fullWidth />
        </SimpleForm>
      </Create>
    </>
  );
};

export default MailCreate;
