import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ReservationCreate from './ReservationCreate';

import ReservationList from './ReservationList';

export default {
  list: ReservationList,
  icon: LocalOfferIcon,
  create: ReservationCreate,
  recordRepresentation: (record: any) => ` ${record.confirmation_number}`,
};
