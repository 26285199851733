/* eslint react/jsx-key: off */
import {
  BooleanField,
  Datagrid,
  DateField,
  Identifier,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

const GuestViewEmbedded = ({ id }: { id?: Identifier }) => (
  <Show title=" " id={id}>
    <SimpleShowLayout>
      <TextField source="first_name" />
      <TextField source="last_name" />
      <TextField source="email" />
      <BooleanField source="is_sent_mail" />
      <ReferenceManyField label="Reservation" reference="guest_reservations_view" target="guest_id">
        <Datagrid>
          <TextField source="id" />
          <TextField source="confirmation_number" />
          <ReferenceField source="room_id" reference="room">
            <TextField source="number" />
          </ReferenceField>
          <DateField showTime source="arrival_time" locales="de-AT" />
          <DateField showTime source="departure_time" locales="de-AT" />
        </Datagrid>
      </ReferenceManyField>

      <ReferenceManyField label="Mail" reference="mail" target="guest_id">
        <Datagrid>
          <TextField source="id" />
          <BooleanField source="is_sent" />
          <TextField source="from" />
          <TextField source="to" />
          <TextField source="title" />
          <ReferenceField source="language" reference="language">
            <TextField source="name" />
          </ReferenceField>
          <DateField source="last_sent" showTime locales="de-AT" />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);

export default GuestViewEmbedded;
