/* eslint react/jsx-key: off */
import { Datagrid, DateField, List, ReferenceField, TextField } from 'react-admin';

import SettingEditEmbedded from './SettingEditEmbedded';

const SettingList = () => {
  return (
    <List>
      <Datagrid expand={<SettingEditEmbedded />} rowClick="expand" optimized>
        <TextField source="id" />
        <TextField source="key" />
        <ReferenceField source="updated_by" reference="user" />
        <DateField source="updated_at" showTime locales="de-AT" />
      </Datagrid>
    </List>
  );
};

export default SettingList;
