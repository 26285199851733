import { defaultTheme } from 'react-admin';

export const darkTheme = {
  palette: {
    primary: {
      main: '#90caf9',
    },
    secondary: {
      main: '#FBBA72',
    },
    mode: 'dark' as const, // Switching the dark mode on is a single property value change.
  },
  sidebar: {
    width: 200,
  },
  components: {
    ...defaultTheme.components,
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          borderLeft: '3px solid #000',
          '&.RaMenuItemLink-active': {
            borderLeft: '3px solid #90caf9',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          color: '#ffffffb3',
          backgroundColor: '#616161e6',
        },
      },
    },
  },
};

export const lightTheme = {
  palette: {
    colorMain: '#73879D',
    primary: {
      main: '#73879D',
    },
    secondary: {
      light: '#5f5fc4',
      main: '#283593',
      dark: '#001064',
      contrastText: '#fff',
    },
    background: {
      default: '#fcfcfe',
    },
    mode: 'light' as const,
  },
  shape: {
    borderRadius: 10,
  },
  sidebar: {
    width: 250,
  },

  components: {
    ...defaultTheme.components,
    MuiTextField: {
      defaultProps: {
        variant: 'filled' as const,
        margin: 'dense' as const,
        size: 'medium' as const,
      },
    },
    RaLayout: {
      styleOverrides: {
        root: {
          '& .RaLayout-content': {
            background: '#F7F7F7',
            padding: 0,
          },
        },
      },
    },
    RaSidebar: {
      styleOverrides: {
        root: {
          background: 'none',
          '& .RaSidebar-paper,.RaSidebar-fixed': {
            background: '#405467',
          },
          '.RaSidebar-fixed': {
            height: 'calc(100vh)',
          },
          '& .RaMenu-open': {},
        },
      },
    },
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          color: '#FFF',
          borderRight: '3px solid rgba(0,0,0,0)',
          '&.RaMenuItemLink-active': {
            borderRight: '3px solid #57BB9C',
            color: '#FFF',
            background: 'rgba(255, 255, 255, 0.04)',
          },
          '&:hover': {
            background: 'rgba(255, 255, 255, 0.04)',
          },
          '& svg': {
            color: '#FFF',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: 'none',
        },
        root: {
          border: '1px solid #e0e0e3',
          backgroundClip: 'padding-box',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          color: '#808080',
          backgroundColor: '#fff',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#f5f5f5',
        },
        barColorPrimary: {
          backgroundColor: '#d7d7d7',
        },
      },
    },
  },
};

export const buttonStyles = {
  info: {
    backgroundColor: '#405467',
    margin: '0 5px',
    color: '#fff',
    fontSize: '12px',
    borderRadius: '5px',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#405467',
    },
  },
  warning: {
    backgroundColor: '#f44336',
    color: '#fff',
    margin: '0 5px',
    fontSize: '12px',
    borderRadius: '5px',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#f44336',
    },
  },
};
