/* eslint react/jsx-key: off */
import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  Edit,
  Identifier,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useRecordContext,
} from 'react-admin';

const MailTemplateEditEmbedded = ({ id }: { id?: Identifier }) => {
  const record = useRecordContext();
  const [body, setBody] = useState(record.body ?? '');
  return (
    <Edit title=" " id={id} mutationMode="pessimistic">
      <SimpleForm>
        <ReferenceInput source="type" reference="mail_template_type" alwaysOn>
          <SelectInput optionText="name" source="id" />
        </ReferenceInput>
        <ReferenceInput source="language" reference="language" validate={required()}>
          <SelectInput optionText="name" source="id" />
        </ReferenceInput>
        <TextInput source="title" fullWidth />
        <TextInput
          source="body"
          multiline
          minRows={20}
          fullWidth
          onChange={(e) => {
            setBody(e.target.value);
          }}
        />
        <div dangerouslySetInnerHTML={{ __html: body }}></div>
      </SimpleForm>
    </Edit>
  );
};

MailTemplateEditEmbedded.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
  id: PropTypes.string,
};

export default MailTemplateEditEmbedded;
