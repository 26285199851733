/* eslint react/jsx-key: off */
import PeopleIcon from '@mui/icons-material/People';
import { ReactElement } from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  ReferenceField,
  SearchInput,
  TextField,
  usePermissions,
} from 'react-admin';
import { Role_Enum } from 'src/utils/enum';
import { CreateKeyButton } from './CreateKeyButton';
import { FullNameField } from './FullNameField';
import GuestViewEmbedded from './GuestViewEmbedded';

export const GuestIcon = PeopleIcon;

// function that returns an array of filters in react admin
const getGuestFilters = (): ReactElement[] => {
  const filters = [
    <SearchInput source="first_name,phone,last_name,email" alwaysOn />,

    // permissions === 'master' ? <TextInput source="role" /> : null,
  ];
  return filters.filter((filter) => filter !== null) as ReactElement[];
};

const GuestList = () => {
  const { permissions } = usePermissions();
  return (
    <List
      filters={getGuestFilters()}
      // filterDefaultValues={{ role: 'guest' }}
      sort={{ field: 'created_at', order: 'DESC' }}
    >
      <Datagrid expand={<GuestViewEmbedded />} rowClick="expand" optimized>
        <TextField source="id" />
        <FullNameField />
        <TextField source="email" />
        <BooleanField source="is_sent_mail" />
        <ReferenceField source="language" reference="language">
          <TextField source="name" />
        </ReferenceField>
        <CreateKeyButton />
        {permissions === Role_Enum.Master && (
          <ReferenceField source="created_by" reference="user">
            <FullNameField />
          </ReferenceField>
        )}
        <DateField source="created_at" showTime locales="de-AT" />
      </Datagrid>
    </List>
  );
};

export default GuestList;
