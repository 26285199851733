import AddIcon from '@mui/icons-material/Add';
import { Button, useRecordContext } from 'react-admin';
import { Link } from 'react-router-dom';
export const CreateKeyButton = (props: any) => {
  const record = useRecordContext(props);

  return record ? (
    <Button
      color="primary"
      startIcon={<AddIcon />}
      label="resources.reservation.actions.add_key"
      component={Link}
      to={`/key/create?reservation_id=${record.id}`}
    />
  ) : null;
};

CreateKeyButton.defaultProps = { label: 'resources.reservation.fields.create_key' };
