import SyncIcon from '@mui/icons-material/Sync';

import SyncCreate from './SyncCreate';
import SyncList from './SyncList';

export default {
  list: SyncList,
  create: SyncCreate,
  icon: SyncIcon,
  recordRepresentation: (record: any) => `${record.mode} ${record.created_at}`,
};
