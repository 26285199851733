/* eslint react/jsx-key: off */
import { Datagrid, DateField, List, ReferenceField, TextField, usePermissions } from 'react-admin';

import MailTemplateEditEmbedded from './MailTemplateEditEmbedded';
import { Role_Enum } from 'src/utils/enum';

const MailTemplateList = () => {
  const { permissions } = usePermissions();
  return (
    <List queryOptions={{ refetchInterval: 5000 }} sort={{ field: 'updated_at', order: 'DESC' }}>
      <Datagrid expand={<MailTemplateEditEmbedded />} rowClick="expand" optimized>
        <TextField source="id" />
        <TextField source="title" />
        <ReferenceField source="language" reference="language">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="type" reference="mail_template_type">
          <TextField source="name" />
        </ReferenceField>
        {permissions === Role_Enum.Master && <ReferenceField source="created_by" reference="user" />}
        <DateField source="updated_at" sortable showTime locales="de-AT" />
      </Datagrid>
    </List>
  );
};

export default MailTemplateList;
