import MailIcon from '@mui/icons-material/Mail';

import MailCreate from './MailCreate';
import MailList from './MailList';

export default {
  list: MailList,
  create: MailCreate,
  icon: MailIcon,
  recordRepresentation: (record: any) => `${record.title}`,
};
