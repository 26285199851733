import IconBack from '@mui/icons-material/ArrowBack';
import { Button } from 'react-admin';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
  const navigate = useNavigate();
  return <Button onClick={() => navigate(-1)} label="" startIcon={<IconBack />}></Button>;
};

export default BackButton;
