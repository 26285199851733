/* eslint react/jsx-key: off */
import { BooleanField, Datagrid, DateField, List, ReferenceField, TextField, usePermissions } from 'react-admin';
import { FullNameField } from '../guests/FullNameField';

import { Role_Enum } from 'src/utils/enum';
import MailViewEmbedded from './MailViewEmbedded';

const MailList = () => {
  const { permissions } = usePermissions();
  return (
    <List queryOptions={{ refetchInterval: 5000 }} sort={{ field: 'last_sent', order: 'DESC' }}>
      <Datagrid expand={<MailViewEmbedded />} rowClick="expand" optimized>
        <TextField source="id" />
        <BooleanField source="is_sent" />
        <ReferenceField source="guest_id" reference="guest">
          <FullNameField />
        </ReferenceField>
        <TextField source="from" />
        <TextField source="to" />
        <TextField source="title" />
        <ReferenceField source="language" reference="language">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="last_sent" showTime locales="de-AT" />

        {permissions === Role_Enum.Master && <ReferenceField source="created_by" reference="user" />}
        <DateField source="created_at" showTime locales="de-AT" />
      </Datagrid>
    </List>
  );
};

export default MailList;
