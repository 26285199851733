/* eslint react/jsx-key: off */
import { BooleanField, Datagrid, DateField, List, TextField } from 'react-admin';
import GroupEditEmbedded from './GroupEditEmbedded';

const GroupList = () => {
  return (
    <List queryOptions={{ refetchInterval: 5000 }} sort={{ field: 'updated_at', order: 'DESC' }}>
      <Datagrid optimized rowClick={'expand'} expand={<GroupEditEmbedded />}>
        <TextField source="id" />
        <TextField source="name" />
        <BooleanField source="is_public" />
        <BooleanField source="is_pre_checkin" />
        <BooleanField source="is_post_checkin" />
        <BooleanField source="is_schedule" />
        <BooleanField source="is_post_checkout" />
        <BooleanField source="is_seebad" />
        <DateField source="updated_at" showTime locales="de-AT" />
      </Datagrid>
    </List>
  );
};

export default GroupList;
