import { useRecordContext } from 'react-admin';

export const FullNameField = (props: any) => {
  const record = useRecordContext(props);
  return record ? (
    <span>
      {record.first_name} {record.last_name}
    </span>
  ) : null;
};

FullNameField.defaultProps = { label: 'Name' };
