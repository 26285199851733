/* eslint react/jsx-key: off */
import PropTypes from 'prop-types';
import {
  DateField,
  Identifier,
  NumberField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  usePermissions,
  useTranslate,
} from 'react-admin';
import { Role_Enum } from 'src/utils/enum';

const SyncViewEmbedded = ({ id }: { id?: Identifier }) => {
  const translate = useTranslate();
  const { permissions } = usePermissions();
  return (
    <Show id={id} queryOptions={{ refetchInterval: 5000 }}>
      <SimpleShowLayout>
        <TextField source="id" />
        <ReferenceField source="mode" reference="sync_mode">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="status" reference="sync_status">
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="total" />
        <NumberField source="process" />
        <TextField source="note" emptyText={translate('sync.list.note_empty')} />
        {permissions === Role_Enum.Master && <ReferenceField source="created_by" reference="user" />}
        <DateField source="created_at" showTime locales="de-AT" />
      </SimpleShowLayout>
    </Show>
  );
};

SyncViewEmbedded.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
  id: PropTypes.string,
};

export default SyncViewEmbedded;
