/* eslint react/jsx-key: off */
import moment from 'moment';
import { Create, DateTimeInput, ReferenceInput, required, SelectInput, SimpleForm } from 'react-admin';

const SyncCreate = () => {
  return (
    <Create redirect="show">
      <SimpleForm defaultValues={{ schedule: moment().format() }}>
        <ReferenceInput
          source="mode"
          reference="sync_mode"
          autoFocus
          validate={[required()]}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <SelectInput optionText="name" validate={[required()]} />
        </ReferenceInput>
        <DateTimeInput source="schedule" validate={[required()]} />
      </SimpleForm>
    </Create>
  );
};

export default SyncCreate;
