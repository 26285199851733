/* eslint react/jsx-key: off */
import {
  Datagrid,
  DateField,
  List,
  NumberField,
  ReferenceField,
  TextField,
  usePermissions,
  useTranslate,
} from 'react-admin';

import { Role_Enum } from 'src/utils/enum';
import SyncViewEmbedded from './SyncViewEmbedded';

const SyncList = () => {
  const { permissions } = usePermissions();
  const translate = useTranslate();
  return (
    <List queryOptions={{ refetchInterval: 5000 }} sort={{ field: 'updated_at', order: 'DESC' }}>
      <Datagrid expand={<SyncViewEmbedded />} rowClick="expand" optimized>
        <TextField source="id" />
        <ReferenceField source="mode" reference="sync_mode">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="status" reference="sync_status">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="schedule" showTime locales="de-AT" />
        <NumberField source="total" />
        <NumberField source="process" />
        <TextField source="note" emptyText={translate('sync.list.note_empty')} />
        {permissions === Role_Enum.Master && <ReferenceField source="created_by" reference="user" />}

        <DateField source="created_at" showTime locales="de-AT" />
      </Datagrid>
    </List>
  );
};

export default SyncList;
