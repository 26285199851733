import MailIcon from '@mui/icons-material/Mail';

import MailTemplateCreate from './MailTemplateCreate';
import MailTemplateList from './MailTemplateList';

export default {
  list: MailTemplateList,
  create: MailTemplateCreate,
  icon: MailIcon,
  recordRepresentation: (record: any) => `${record.title}`,
};
