import PeopleIcon from '@mui/icons-material/People';
import KeyCreate from './KeyCreate';
import KeyEdit from './KeyEdit';

export default {
  // list: KeyList,
  edit: KeyEdit,
  // show: KeyShow,
  create: KeyCreate,
  icon: PeopleIcon,
  recordRepresentation: (record: any) => `${record.last_name}`,
};
