/* eslint react/jsx-key: off */
import moment from 'moment';
import {
  AutocompleteInput,
  Create,
  DateTimeInput,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SimpleForm,
} from 'react-admin';
const ReservationCreate = () => {
  // const filterToQuery = (searchText: string) => ({ last_name: `%${searchText}%` });
  const transform = (formData: any) => {
    formData.id = Math.floor(100000 + Math.random() * 900000);
    formData.confirmation_number = `${Math.floor(100000 + Math.random() * 900000)}`;
    formData.status = 'RESERVED';
    formData.arrival_date = moment(formData.arrival_time).format('YYYY-MM-DD');
    formData.departure_date = moment(formData.departure_time).format('YYYY-MM-DD');
    return formData;
  };

  const filterToQuery = (searchText: string) => ({ number: `%${searchText}%` });

  return (
    <Create resource="reservation" transform={transform} redirect="list">
      <SimpleForm>
        <DateTimeInput source="arrival_time" />
        <DateTimeInput source="depature_time" />
        <ReferenceField source="room_id" reference="room">
          <NumberInput source="number" />
        </ReferenceField>

        <ReferenceInput source="room_id" reference="room" sort={{ field: 'number', order: 'ASC' }}>
          <AutocompleteInput filterToQuery={filterToQuery} fullWidth />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default ReservationCreate;
