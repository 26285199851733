import EventNoteIcon from '@mui/icons-material/EventNote';
import LogList from './LogList';
import LogShow from './LogShow';

export default {
  list: LogList,
  show: LogShow,
  icon: EventNoteIcon,
  recordRepresentation: (record: any) => `${record.id}`,
};
