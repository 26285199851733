/* eslint react/jsx-key: off */
import { Create, email, ReferenceInput, required, SelectInput, SimpleForm, TextInput } from 'react-admin';

import Aside from './Aside';

const isValidName = async (value: string) =>
  new Promise<string | undefined>((resolve) =>
    setTimeout(() => resolve(value === 'Admin' ? "Can't be Admin" : undefined)),
  );

const UserCreate = () => {
  return (
    <Create aside={<Aside />} redirect="show">
      <SimpleForm mode="onBlur" warnWhenUnsavedChanges>
        <TextInput source="first_name" autoFocus validate={[required(), isValidName]} />
        <TextInput source="last_name" validate={[required(), isValidName]} />
        <TextInput source="email" validate={[required(), email()]} />
        <TextInput source="password" validate={[required()]} />

        <ReferenceInput source="role" reference="role">
          <SelectInput optionText="name" validate={[required()]} />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default UserCreate;
