/* eslint react/jsx-key: off */
import {
  Datagrid,
  Identifier,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

const ReservationViewEmbedded = ({ id }: { id?: Identifier }) => (
  <Show title=" " id={id}>
    <SimpleShowLayout>
      <ReferenceField label="Room Number" source="room_id" reference="room">
        <TextField source="number" />
      </ReferenceField>
      <ReferenceField label="Room Status" source="room_id" reference="room">
        <TextField source="status" />
      </ReferenceField>

      <ReferenceManyField label="Guests" reference="reservation_guests_view" target="reservation_id">
        <Datagrid>
          <TextField source="first_name" />
          <TextField source="last_name" />
          <TextField source="email" />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);

export default ReservationViewEmbedded;
