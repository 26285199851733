/* eslint react/jsx-key: off */
import { useState } from 'react';
import { Create, ReferenceInput, required, SelectInput, SimpleForm, TextInput } from 'react-admin';

const MailTemplateCreate = () => {
  const [body, setBody] = useState('');
  return (
    <Create redirect="show">
      <SimpleForm>
        <ReferenceInput source="type" reference="mail_template_type">
          <SelectInput optionText="name" source="id" />
        </ReferenceInput>
        <ReferenceInput source="language" reference="language" validate={required()}>
          <SelectInput optionText="name" source="id" />
        </ReferenceInput>
        <TextInput source="title" fullWidth />
        <TextInput
          source="body"
          multiline
          minRows={20}
          fullWidth
          onChange={(e) => {
            setBody(e.target.value);
          }}
        />
        <div dangerouslySetInnerHTML={{ __html: body }}></div>
      </SimpleForm>
    </Create>
  );
};

export default MailTemplateCreate;
