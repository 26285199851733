/* eslint react/jsx-key: off */
import { BooleanInput, Edit, Identifier, SimpleForm, TextField } from 'react-admin';

const RoomEditEmbedded = ({ id }: { id?: Identifier }) => {
  return (
    <Edit title=" " id={id}>
      <SimpleForm>
        <TextField source="id" />
        <TextField source="name" />
        <BooleanInput source="is_public" />
        <BooleanInput source="is_pre_checkin" />
        <BooleanInput source="is_post_checkin" />
        <BooleanInput source="is_schedule" />
        <BooleanInput source="is_post_checkout" />
        <BooleanInput source="is_seebad" />
      </SimpleForm>
    </Edit>
  );
};

export default RoomEditEmbedded;
