/* eslint react/jsx-key: off */
import { Create, required, SimpleForm, TextInput } from 'react-admin';

const SettingCreate = () => {
  return (
    <Create redirect="show">
      <SimpleForm>
        <TextInput source="key" autoFocus validate={[required()]} />
        <TextInput source="value" validate={[required()]} fullWidth />
        <TextInput source="description" fullWidth multiline />
      </SimpleForm>
    </Create>
  );
};

export default SettingCreate;
