import { Card, SxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useCheckAuth } from 'ra-core';
import { HtmlHTMLAttributes, ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginForm } from 'src/components/form/';
export const Login = (props: LoginProps) => {
  const checkAuth = useCheckAuth();
  const navigate = useNavigate();
  useEffect(() => {
    checkAuth({}, false)
      .then(() => {
        navigate('/');
      })
      .catch(() => {});
  }, [checkAuth, navigate]);

  return (
    <Root {...props}>
      <Card className={LoginClasses.card}>
        <SPageTitle>Login</SPageTitle>
        <LoginForm />
      </Card>
    </Root>
  );
};

export interface LoginProps extends HtmlHTMLAttributes<HTMLDivElement> {
  backgroundImage?: string;
  children?: ReactNode;
  className?: string;
  sx?: SxProps;
}

const PREFIX = 'RaLogin';
export const LoginClasses = {
  card: `${PREFIX}-card`,
};

const Root = styled('div', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  height: '1px',
  alignItems: 'center',
  justifyContent: 'flex-start',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundImage: 'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',

  [`& .${LoginClasses.card}`]: {
    minWidth: 300,
    marginTop: '6em',
  },
}));

Login.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

const SPageTitle = styled('h1')({
  fontSize: '20px',
  marginBlockStart: '16px',
  textAlign: 'center',
});

export default Login;
