/* eslint react/jsx-key: off */
import { Create, required, SimpleForm, TextInput } from 'react-admin';

import Aside from './Aside';

const GuestCreate = () => {
  const transform = (formData: any) => {
    formData.id = Math.floor(100000 + Math.random() * 900000);
    return formData;
  };

  return (
    <Create aside={<Aside />} transform={transform} redirect="list">
      <SimpleForm>
        <TextInput source="first_name" validate={required()} autoFocus />
        <TextInput source="last_name" validate={required()} />
        <TextInput source="email" />
      </SimpleForm>
    </Create>
  );
};

export default GuestCreate;
