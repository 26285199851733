/* eslint react/jsx-key: off */
import moment from 'moment';
import 'moment/locale/de'; // without this line it didn't work
import { AutocompleteInput, Create, ReferenceInput, SimpleForm } from 'react-admin';
import { useLocation } from 'react-router-dom';

const KeyCreate = () => {
  const location = useLocation();
  const guest_id = new URLSearchParams(location.search).get('guest_id');
  const reservation_id = new URLSearchParams(location.search).get('reservation_id');

  const transform = (formData: any) => {
    formData.code = '';
    if (guest_id) {
      formData.guest_id = guest_id;
    }
    if (reservation_id) {
      formData.reservation_id = reservation_id;
    }

    return formData;
  };

  const filterGuestToQuery = (searchText: string) => ({ 'last_name@_ilike,first_name@_ilike': `%${searchText}%` });
  const filterReservationToQuery = (searchText: string) => ({ 'confirmation_number@_ilike': `%${searchText}%` });
  const optionTextReservation = (record: any) => {
    return `${record.confirmation_number} (${moment(record.arrival_time).format('DD.MM')} -> ${moment(
      record.departure_time,
    ).format('DD.MM')})`;
  };

  return (
    <Create transform={transform} redirect="/key_view">
      <SimpleForm>
        {!reservation_id && (
          <ReferenceInput source="reservation_id" reference="reservation">
            <AutocompleteInput
              label="resources.key_view.fields.confirmation_number"
              optionText={optionTextReservation}
              fullWidth
              filterToQuery={filterReservationToQuery}
            />
          </ReferenceInput>
        )}

        {!guest_id && (
          <ReferenceInput source="guest_id" reference="guest">
            <AutocompleteInput fullWidth filterToQuery={filterGuestToQuery} />
          </ReferenceInput>
        )}
      </SimpleForm>
    </Create>
  );
};

export default KeyCreate;
