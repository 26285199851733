import SettingsIcon from '@mui/icons-material/Settings';

import SettingCreate from './SettingCreate';
import SettingList from './SettingList';

export default {
  list: SettingList,
  create: SettingCreate,
  icon: SettingsIcon,
  recordRepresentation: (record: any) => `${record.key}`,
};
