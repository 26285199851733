/* eslint react/jsx-key: off */
import * as React from 'react';
import { AutocompleteArrayInput, Edit, ReferenceArrayInput, SaveButton, SimpleForm, Toolbar } from 'react-admin';

import Aside from './Aside';

interface Props {
  [key: string]: any;
}
/**
 * Custom Toolbar for the Edit form
 *
 * Save with undo, but delete with confirm
 */
const KeyEditToolbar: React.FC<Props> = (props) => {
  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }} {...props}>
      <SaveButton />
    </Toolbar>
  );
};

const KeyEditForm = () => {
  const filterToQuery = (searchText: string) => ({ name: `%${searchText}%`, is_public: true });
  return (
    <SimpleForm toolbar={<KeyEditToolbar />}>
      <ReferenceArrayInput
        label="Groups"
        source="group_ids"
        reference="group"
        perPage={320}
        sort={{ field: 'name', order: 'ASC' }}
        filter={{ is_public: true }}
      >
        <AutocompleteArrayInput optionText="name" fullWidth filterToQuery={filterToQuery} />
      </ReferenceArrayInput>
    </SimpleForm>
  );
};
const KeyEdit = () => {
  return (
    <Edit title="resources.key.edit.title" redirect="/key_view" aside={<Aside />} mutationMode="pessimistic">
      <KeyEditForm />
    </Edit>
  );
};

export default KeyEdit;
