/* eslint react/jsx-key: off */
import * as React from 'react';
import {
  CloneButton,
  DeleteWithConfirmButton,
  Edit,
  email,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  ShowButton,
  TabbedForm,
  TextInput,
  Toolbar,
  TopToolbar,
  usePermissions,
} from 'react-admin';

import Aside from './Aside';

interface Props {
  [key: string]: any;
}
/**
 * Custom Toolbar for the Edit form
 *
 * Save with undo, but delete with confirm
 */
const UserEditToolbar: React.FC<Props> = (props) => {
  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }} {...props}>
      <SaveButton />
      <DeleteWithConfirmButton />
    </Toolbar>
  );
};

const EditActions = () => (
  <TopToolbar>
    <CloneButton className="button-clone" />
    <ShowButton />
  </TopToolbar>
);

const UserEditForm = () => {
  const { permissions } = usePermissions();

  return (
    <TabbedForm defaultValues={{ role: 'user' }} toolbar={<UserEditToolbar />}>
      <TabbedForm.Tab label="user.form.summary" path="">
        {permissions === 'master' && <TextInput disabled source="id" />}
        <TextInput source="first_name" validate={required()} disabled={permissions !== 'master'} />
        <TextInput source="last_name" validate={required()} disabled={permissions !== 'master'} />
        <TextInput source="email" validate={[required(), email()]} disabled={permissions !== 'master'} />
      </TabbedForm.Tab>
      {permissions === 'master' && (
        <TabbedForm.Tab label="user.form.security" path="security">
          <TextInput source="password" />
          <ReferenceInput source="role" reference="role">
            <SelectInput optionText="name" validate={[required()]} />
          </ReferenceInput>
        </TabbedForm.Tab>
      )}
    </TabbedForm>
  );
};
const UserEdit = () => {
  return (
    <Edit aside={<Aside />} actions={<EditActions />} mutationMode="pessimistic">
      <UserEditForm />
    </Edit>
  );
};

export default UserEdit;
