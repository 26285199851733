/* eslint react/jsx-key: off */
import PropTypes from 'prop-types';
import {
  BooleanField,
  DateField,
  Identifier,
  ReferenceField,
  RichTextField,
  Show,
  SimpleShowLayout,
  TextField,
  usePermissions,
} from 'react-admin';
import { Role_Enum } from 'src/utils/enum';
import { FullNameField } from '../guests/FullNameField';

const MailViewEmbedded = ({ id }: { id?: Identifier }) => {
  const { permissions } = usePermissions();
  return (
    <Show title=" " id={id} queryOptions={{ refetchInterval: 5000 }}>
      <SimpleShowLayout>
        <TextField source="id" />
        <BooleanField source="is_sent" />
        <ReferenceField source="guest_id" reference="guest">
          <FullNameField />
        </ReferenceField>
        <TextField source="from" />
        <TextField source="to" />
        <ReferenceField source="language" reference="language">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="title" />
        <RichTextField source="body" />

        <DateField source="last_sent" showTime locales="de-AT" />

        {permissions === Role_Enum.Master && <ReferenceField source="created_by" reference="user" />}
        <DateField source="created_at" showTime locales="de-AT" />
      </SimpleShowLayout>
    </Show>
  );
};

MailViewEmbedded.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
  id: PropTypes.string,
};

export default MailViewEmbedded;
