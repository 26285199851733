import { DateField, Identifier, ReferenceField, Show, SimpleShowLayout, TextField } from 'react-admin';
import { JsonViewField } from './JsonViewField';

const LogShow = ({ id }: { id?: Identifier }) => {
  return (
    <Show id={id}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="title" />
        <TextField source="message" />
        <JsonViewField />
        <ReferenceField source="module" reference="log_module">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="level" reference="log_level">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="created_by" reference="user" />
        <DateField source="created_at" showTime locales="de-AT" />
      </SimpleShowLayout>
    </Show>
  );
};

export default LogShow;
