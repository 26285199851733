import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import RoomEdit from './RoomEdit';

import RoomList from './RoomList';

export default {
  list: RoomList,
  icon: MeetingRoomIcon,
  edit: RoomEdit,
  recordRepresentation: (record: any) => ` ${record.number}`,
};
